import { getFilterRenderTypeByGroupKey } from '../../../components/vehicle/vehicle-product-list/vehicle-product-list-filter/filter.helpers';
import { Aggregate, AggregateObject, ShopFacetGroup, ShopFacetOption } from '../../api/models/shop/product-list';
import { GlobalProductListSettings } from '../../api/models/umbraco';
import { FacetOptionLabel } from '../../api/models/umbraco/content-spot';

export const createShopProductListData = (apiAggregates: Aggregate, productlistSettings: GlobalProductListSettings): ShopFacetGroup[] => {
    const shopFacetGroups: ShopFacetGroup[] = [];
    Object.keys(apiAggregates).forEach((key) => {
        const aggregate = apiAggregates[key];
        if (aggregate.length > 0) {
            shopFacetGroups.push(mapSingleAggregate(aggregate, key, productlistSettings));
        }
    });
    return shopFacetGroups;
};

const addIcon = (aggregate: AggregateObject, key: string, facetOptionConfig?: FacetOptionLabel): { icon: string } | null => {
    if (key === 'mainColors') {
        const colorResource = aggregate.subAggregates ? aggregate.subAggregates['colorResource'] : null;
        if (colorResource)
            return {
                icon: colorResource[0].value,
            };
    } else if (facetOptionConfig && facetOptionConfig.facetOptionIcon) {
        return {
            icon: facetOptionConfig.facetOptionIcon.src,
        };
    }
    return null;
};

const mapSingleAggregate = (aggregate: AggregateObject[], key: string, productlistSettings: GlobalProductListSettings): ShopFacetGroup => {
    const umbracoLabelMapping = productlistSettings.facetGroupTranslations.find((x) => x.facetGroupKey === key);
    return {
        key,
        label: umbracoLabelMapping ? umbracoLabelMapping.facetGroupLabelText : key,
        facetOptions: [
            ...aggregate.map((x) => {
                return addFacetOption(x, key, productlistSettings);
            }),
        ],
    };
};

const addSubfacets = (
    aggregate: AggregateObject,
    productlistSettings: GlobalProductListSettings,
    parentKey: string
): { subFacets: ShopFacetGroup[] } | null => {
    if (aggregate.subAggregates && Object.keys(aggregate.subAggregates).length > 0) {
        const subFacetGroups: ShopFacetGroup[] = [];
        Object.keys(aggregate.subAggregates).forEach((key) => {
            const agg = aggregate.subAggregates[key];
            if (agg.length > 0) {
                subFacetGroups.push(mapSingleAggregate(agg, parentKey, productlistSettings));
            }
        });
        return { subFacets: subFacetGroups };
    } else {
        return null;
    }
};

const addFacetOption = (x: AggregateObject, key: string, productlistSettings: GlobalProductListSettings): ShopFacetOption => {
    const isIntervalValue = getFilterRenderTypeByGroupKey(key) === 'Dropdown interval';
    const facetOptionConfig = productlistSettings.facetGroupTranslations
        .find((x) => x.facetGroupKey === key)
        ?.facetOptions.find((y) => y.facetOptionKey === x.key);

    return {
        ...x,
        filterQueryValue: isIntervalValue ? x.key : `${key}__${x.key}`,
        key: x.key,
        ...addIcon(x, key, facetOptionConfig),
        ...addSubfacets(x, productlistSettings, key),
        value: facetOptionConfig && facetOptionConfig.facetOptionLabelText.length > 0 ? facetOptionConfig.facetOptionLabelText : x.value,
    };
};
