import { getAPI } from '../helpers';
import { ShopProductsApiResponse } from '../models/shop/shop-api.models';
import { APIResponse } from '../types';

const baseShopProductApiUrl = 'api/v2/shopproducts';

export type ShopProductlistRequest = {
    categoryId: string;
    take: number;
    from: number;
    filters: string[];
    sort?: string;
};

export const getShopProduct = async (productId: string): APIResponse<ShopProductsApiResponse> => {
    return getAPI<ShopProductsApiResponse>(`${baseShopProductApiUrl}`, {
        filter: `parentId__${productId}`,
    });
};

export const getShopProductsByCategory = async (params: ShopProductlistRequest): APIResponse<ShopProductsApiResponse> => {
    const baseRequest = {
        filter: `categories__${params.categoryId}`,
        take: params.take,
        from: params.from,
    };
    const requestWithSorting =
        params.sort && params.sort.length > 0
            ? {
                  ...baseRequest,
                  sort: params.sort,
              }
            : baseRequest;
    const request =
        params.filters.length > 0
            ? {
                  ...requestWithSorting,
                  postFilter: params.filters,
              }
            : requestWithSorting;
    return getAPI<ShopProductsApiResponse>(`${baseShopProductApiUrl}`, request);
};
